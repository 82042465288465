import React from "react"
import Link from "gatsby-link"
import Layout from "../components/layout"
import SEO from "../components/SEO"

export default () => (
  <Layout>
    <SEO title="JTF starter site page-css" description="Not much here!" />
    <div className="content">
      <h1>About CSS Modules</h1>
      <p>CSS Modules are very cool</p>
      <p>I might want to do something like this:</p>
      <blockquote>
        <code>
          ul {'{'}<br />
          &nbsp; &nbsp; &nbsp; list-style: none; <br />
          {'}'}<br /><br />
          li {'{'}<br />
          &nbsp; &nbsp; &nbsp; padding: 0px 10px 10px 20px; <br />
          {'}'}
        </code>
      </blockquote>
      <p>...and then again, maybe not!</p>
      <Link to="/">Home</Link>
    </div>
  </Layout>
)